export const STORAGE_PROVIDERS = {
  dropbox: "dropbox",
  google: "google",
  aws: "aws",
};
export function detectStorageProvider(link) {
  const providers = {
    dropbox: /(?:www\.)?dropbox\.com/,
    google: /(?:www\.)?drive\.google\.com/,
    aws: /\.s3\.[a-z0-9-]+\.amazonaws\.com/,
  };

  for (const [provider, regex] of Object.entries(providers)) {
    if (regex.test(link)) {
      return provider;
    }
  }
  return "unknown";
}

export const extractDriveId = (url) => {
  const filePattern = /\/file\/d\/([a-zA-Z0-9_-]+)/;
  const folderPattern = /\/folders\/([a-zA-Z0-9_-]+)/;

  const fileMatch = url.match(filePattern);
  if (fileMatch) {
    return { id: fileMatch[1], type: "file" };
  }

  const folderMatch = url.match(folderPattern);
  if (folderMatch) {
    return { id: folderMatch[1], type: "folder" };
  }

  throw new Error("Invalid Google Drive URL");
};
