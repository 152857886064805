import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import logoLight from "../assets/images/dropboxed-logo.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ConnectStorage from "../components/ConnectStorage";

const StorageConnect = () => {
  // States
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userId = searchParams.get("userId");
  const redirect_uri = searchParams.get("redirect_uri");
  const access_token = searchParams.get("access_token");

  useEffect(() => {
    if (!userId || !redirect_uri || !access_token) {
      navigate("/login");
    } else {
      setLoading(false);
      localStorage.setItem("accessToken", access_token);
    }
  }, [userId, redirect_uri, access_token]);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  return (
    <div className="" style={{ overflowY: "auto" }}>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div className="content-overlay" />
      <div className="content-wrapper">
        <div className="content-header row"></div>
        <div className="content-body">
          <section className="flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0 mt-4 mb-4">
                <div className="card border-grey border-lighten-3 px-1 py-1 m-0 ">
                  <div className="card-header border-0">
                    <div className="card-title text-center">
                      <div className="p-1 black-logo">
                        <Link to={BASE_URL}>
                          <img src={logoLight} alt="branding logo" width={"150px"} height={"55px"} />
                        </Link>
                      </div>
                    </div>
                    <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                      <span>Connect your storage</span>
                    </h6>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      <fieldset className="form-group position-relative has-icon-left">
                        <ConnectStorage />
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default StorageConnect;
