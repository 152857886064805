import API from "./baseApi";

const getUser = async (data) => {
  try {
    const response = await API.post("/user/getUser", data);
    if (response.status !== 200) {
      throw new Error("Failed to get user");
    }
    return response.data;
  } catch (error) {
    return error.response.data.error;
  }
};

const changeBankingDetails = async (data) => {
  try {
    const response = await API.post("/user/changeBankingDetails", data);
    if (response.status !== 200) {
      throw new Error("Failed to change banking details");
    }
    return response.data;
  } catch (error) {
    return error;
  }
};

const updateUser = async (data) => {
  try {
    const response = await API.post("/user/updateUser", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to create user");
    }
    return response.data;
  } catch (error) {
    return error.response.data.error;
  }
};

const changePassword = async (data) => {
  try {
    const response = await API.post("/user/changePassword", data);
    if (response.status !== 200) {
      throw new Error("Failed to change password");
    }
    return response.data;
  } catch (error) {
    return error.response.data.error;
  }
};

const modifieTemplate = async (data) => {
  try {
    const response = await API.post("/template/new-template", data);
    if (response.status >= 400) {
      throw new Error("Failed to change password");
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response.data.error;
  }
};
const getTemplate = async (userId, type = "email") => {
  try {
    const response = await API.get(`/template/template/${userId}?type=${type}`);
    if (response.status !== 200) {
      throw new Error("Failed to get template");
    }
    return response.data;
  } catch (error) {
    return error.response.data.error;
  }
};
const updateUserColor = async (data) => {
  try {
    const response = await API.post("/user/updateUserColor", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to update color.");
    }
    return response.data;
  } catch (error) {
    return error.response.data.error;
  }
};
export {
  getUser,
  updateUser,
  changeBankingDetails,
  changePassword,
  modifieTemplate,
  getTemplate,
  updateUserColor,
};
