import API from "./baseApi";

export const connectStorageAccount = async (data) => {
  try {
    const response = await API.post("/storage/connect", data);
    if (response.status !== 200) {
      throw new Error("Failed to add booking");
    }
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getImagesByCollectionId = async (id) => {
  try {
    const response = await API.get("/storage/get-images?collectionId=" + id);
    if (response.status !== 200) {
      throw new Error("Failed to add booking");
    }
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserStorageType = async () => {
  try {
    const response = await API.get("/storage/get-user-storage-type");
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: "Something went wrong. Please try again.",
    };
  }
};

export const getGoogleDriveTokens = async (id) => {
  try {
    const response = await API.get("/storage/get-google-drive-tokens?collectionId=" + id);
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: "Something went wrong. Please try again.",
    };
  }
};
