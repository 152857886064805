import React, { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { modifieTemplate, getTemplate } from "../api/userApis";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../context/authContext";
import { getAllClients } from "../api/clientApis";
import Select from "react-select";
import { verifyToken } from "../api/authApis";

function NewsletterTemplate() {
  const editorRef = useRef(null);
  const { authData, setAuthUser } = useAuth();
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [emailSubject, setEmailSubject] = useState("");
  const [isOptionDisabled, setIsOptionDisabled] = useState(false);
  const { token: accesstoken } = authData;
  useEffect(() => {
    const fetchData = async () => {
      if (accesstoken !== undefined) {
        let resp = await verifyToken(accesstoken);
        if (!resp.success) {
          toast.error("Session expired, please login again.");
          window.location.href = "/login";
        } else {
          setAuthUser(resp.user);
        }
      }
    };

    fetchData();
  }, [accesstoken]);

  useEffect(() => {
    (async () => {
      let clientData = await getAllClients({
        subdomainId: authData.user.subdomain_id,
      });
      setClients(
        clientData.data
          .map((provider) => ({
            label: provider.name,
            value: provider.id,
            image: provider.profile_photo,
            email: provider.email,
          }))
          .sort((a, b) => (a.label < b.label ? -1 : 1))
      );
    })();
    const loadTemplate = async (desgin) => {
      if (desgin) {
        editorRef.current?.editor.loadDesign(desgin, (error) => {
          if (error) {
            console.log("Error loading template:", error);
          } else {
            console.log("Template loaded successfully.");
          }
        });
      }
    };
    getTemplate(authData.user.id, "newsletter").then((res) => {
      loadTemplate(res?.template?.jsonDesign);
    });
  }, [authData]);

  const sendEmail = () => {
    if (editorRef.current) {
      editorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        const userId = authData.user.id;
        if (userId) {
          if (selectedClients.length === 0) {
            toast.error("Select atleast one client.");
            return;
          }
          if (
            !emailSubject ||
            emailSubject.length < 10 ||
            emailSubject.length > 100
          ) {
            toast.error(
              "Email subject should be between 10 and 100 characters."
            );
            return;
          }
          let isEmpty = true;
          for (let i = 0; i < design.body.rows.length; i++) {
            const { columns } = design.body.rows[i];
            for (let j = 0; j < columns.length; j++) {
              const { contents } = columns[j];
              if (contents.length) {
                isEmpty = false;
              }
            }
          }
          if (isEmpty) {
            toast.error("Please add some content to email.");
            return;
          }
          let _clients = [];
          if (selectedClients.length > 0) {
            if (selectedClients[0].value === 0) {
              _clients = clients;
            } else {
              _clients = selectedClients;
            }
          }
          modifieTemplate({
            userId,
            html,
            jsonDesign: design,
            type: "newsletter",
            clients: _clients,
            emailSubject,
          })
            .then(() => {
              toast.success("Email sent successfully.");
            })
            .catch((error) => {
              console.error("Error saving the template:", error);
              toast.error("Failed to save template");
            });
        }
      });
    } else {
      console.error("Editor instance not initialized.");
    }
  };

  const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
  return (
    <div
      className="app-content content"
      style={{ padding: "20px 40px 20px 40px" }}
    >
      <div className="content-header-left col-md-4 col-12 mb-2">
        <h2>Email Template Editor</h2>
        <div className="row breadcrumbs-top">
          <div className="breadcrumb-wrapper col-12">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">Email Template</li>
            </ol>
          </div>
        </div>
      </div>
      <div className="bg-white p-2">
        <div className="d-flex justify-content-end mb-2 gap-4 align-items-end">
          <div style={{ minWidth: "200px" }} className="flex-grow-1">
            <label className="required">Select Clients</label>
            <Select
              isMulti
              className="select2"
              name="client"
              value={selectedClients}
              onChange={(values) => {
                const hasZeroValue = values.some((item) => item.value === 0);
                if (hasZeroValue) {
                  setIsOptionDisabled(true);
                  setSelectedClients(values.filter((item) => item.value === 0));
                } else {
                  setIsOptionDisabled(false);
                  setSelectedClients(values);
                }
              }}
              isSearchable
              options={[
                {
                  label: "All Clients",
                  value: 0,
                  image: "",
                  email: "",
                },
                ...clients,
              ]}
              required
              isOptionDisabled={() => isOptionDisabled}
              classNames={{
                clearIndicator: (classes) => classes + " hover:bg-light",
              }}
              components={{
                Option: ({ data, innerRef, innerProps }) => {
                  const isDisabled = isOptionDisabled; // Assuming `isOptionDisabled` is passed in the data prop.

                  return (
                    <div
                      ref={innerRef}
                      {...innerProps}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: isDisabled ? "not-allowed" : "pointer",
                        opacity: isDisabled ? 0.5 : 1,
                      }}
                      className={`customOptionClass ${
                        isDisabled ? "disabledOptionClass" : ""
                      }`}
                    >
                      <img
                        src={
                          data.image
                            ? `${IMAGE_URL}/${data.image}`
                            : "../app-assets/images/portrait/medium/dummy.png"
                        }
                        alt="Profile"
                        style={{
                          marginRight: "10px",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          margin: "4px",
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "../app-assets/images/portrait/medium/dummy.png";
                        }}
                      />
                      <span>{data.label}</span>
                    </div>
                  );
                },
              }}
            />
          </div>
          <button
            className="btn btn-outline-primary py-2 ml-2"
            style={{ height: "40px" }}
            onClick={sendEmail}
          >
            Send Email
          </button>
        </div>
        <div className="mb-1">
          <label className="required">Email Subject</label>
          <input
            type="text"
            className="form-control"
            name="gallery_title"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            required
            placeholder="Email Subject"
          />
        </div>
        <div
          style={{
            marginBottom: "20px",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          <EmailEditor ref={editorRef} style={{ height: "70vh" }} />
        </div>
      </div>
    </div>
  );
}

export default NewsletterTemplate;
