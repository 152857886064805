export const getSubdomainFromUrl = (url, baseUrl) => {
  const subdomainRegex = new RegExp(
    `^(http[s]?:\/\/)?([^\/]+)\\.${baseUrl.replace(/^https?:\/\//, "").replace(/\./g, "\\.")}`
  );
  const match = url.match(subdomainRegex);
  return match ? match[2] : null;
};

export const getDomain = () => {
  // Extract the domain dynamically, e.g., "example.com" from "sub.example.com"
  const hostnameArray = new URL(window.location.href).hostname.split(".");
  return "." + hostnameArray[hostnameArray.length - 1];
};
