import React, { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { modifieTemplate, getTemplate } from "../api/userApis";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../context/authContext";
import { verifyToken } from "../api/authApis";
import { Switch } from "@mui/material";

function EmailTemplate() {
  const editorRef = useRef(null);
  const { authData, setAuthUser } = useAuth();
  const [time, setTime] = useState("12");
  const [enabled, setEnabled] = useState(true);
  const baseTemplate = useRef({
    counters: {
      u_row: 1,
      u_column: 1,
      u_content_text: 7,
      u_content_image: 2,
      u_content_button: 2,
      u_content_social: 1,
    },
    body: {
      id: "AKd5JvFZEZ",
      rows: [
        {
          id: "msTRvrpBbR",
          cells: [1],
          columns: [
            {
              id: "xHonFIQBGK",
              contents: [
                {
                  id: "R4-1Te9qPw",
                  type: "image",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    src: {
                      url: "https://assets.unlayer.com/projects/0/1734580950589-studiio-au-Green.3c721e7c53b5c44da877.png",
                      width: 3094,
                      height: 832,
                      maxWidth: "34%",
                      autoWidth: false,
                    },
                    textAlign: "center",
                    altText: "",
                    action: {
                      name: "web",
                      values: {
                        href: "",
                        target: "_blank",
                      },
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_image_2",
                      htmlClassNames: "u_content_image",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    pending: false,
                  },
                },
                {
                  id: "2jKTsYpOPT",
                  type: "text",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    fontSize: "14px",
                    textAlign: "left",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_text_1",
                      htmlClassNames: "u_content_text",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="line-height: 140%;">Hi @user_name,</p>',
                    _languages: {},
                  },
                },
                {
                  id: "p1Y0e2ISwV",
                  type: "text",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    fontSize: "14px",
                    textAlign: "left",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_text_2",
                      htmlClassNames: "u_content_text",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="line-height: 140%;">This is a reminder about your upcoming appointment with Studiio.au.</p>',
                    _languages: {},
                  },
                },
                {
                  id: "9K_9SbmAOy",
                  type: "text",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    fontSize: "14px",
                    textAlign: "left",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_text_3",
                      htmlClassNames: "u_content_text",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="line-height: 140%;">Appointment Details:<br />Date: @date<br />Time: @time<br />Location: @location</p>',
                    _languages: {},
                  },
                },
                {
                  id: "Dd8k75dN6q",
                  type: "text",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    fontSize: "14px",
                    textAlign: "left",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_text_4",
                      htmlClassNames: "u_content_text",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="line-height: 140%;">We are looking forward to serving you. If you have any questions or need to make any changes to your booking, please feel free to reach out to our support team at info@studiio.au.</p>',
                    _languages: {},
                  },
                },
                {
                  id: "30n44DzB2s",
                  type: "text",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    fontSize: "14px",
                    textAlign: "left",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_text_5",
                      htmlClassNames: "u_content_text",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="line-height: 140%;">Thank you for choosing Studiio.au!</p>',
                    _languages: {},
                  },
                },
                {
                  id: "flXM6XnBze",
                  type: "text",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    fontSize: "14px",
                    textAlign: "left",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_text_6",
                      htmlClassNames: "u_content_text",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="line-height: 140%;">Best regards, <br />Team Studiio.au</p>',
                    _languages: {},
                  },
                },
                {
                  id: "tXgX5cVBCU",
                  type: "button",
                  values: {
                    href: {
                      name: "web",
                      values: {
                        href: "https://www.studiio.au/dashboard",
                        target: "_blank",
                      },
                      attrs: {
                        href: "{{href}}",
                        target: "{{target}}",
                      },
                    },
                    buttonColors: {
                      color: "#FFFFFF",
                      backgroundColor: "#00b5b8",
                      hoverColor: "#FFFFFF",
                      hoverBackgroundColor: "#3AAEE0",
                    },
                    size: {
                      autoWidth: true,
                      width: "100%",
                    },
                    fontSize: "14px",
                    lineHeight: "120%",
                    textAlign: "center",
                    padding: "10px 20px",
                    border: {},
                    borderRadius: "4px",
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    containerPadding: "10px",
                    anchor: "",
                    _meta: {
                      htmlID: "u_content_button_2",
                      htmlClassNames: "u_content_button",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<span style="line-height: 16.8px;">Dashboard</span>',
                    _languages: {},
                    calculatedWidth: 109,
                    calculatedHeight: 37,
                  },
                },
                {
                  id: "eMsIr8IEck",
                  type: "text",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    fontSize: "14px",
                    textAlign: "center",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_text_7",
                      htmlClassNames: "u_content_text",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="line-height: 140%;"><a rel="noopener" href="https://www.studiio.au" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwidmFsdWVzIjp7ImhyZWYiOiJodHRwczovL3d3dy5zdHVkaWlvLmF1IiwidGFyZ2V0IjoiIn19">https://www.studiio.au</a><br />Questions? Reply to this email.</p>',
                    _languages: {},
                  },
                },
              ],
              values: {
                _meta: {
                  htmlID: "u_column_1",
                  htmlClassNames: "u_column",
                },
                border: {},
                padding: "0px",
                borderRadius: "0px",
                backgroundColor: "",
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            _styleGuide: null,
            backgroundColor: "",
            columnsBackgroundColor: "",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: "no-repeat",
              size: "custom",
              position: "center",
            },
            padding: "0px",
            anchor: "",
            hideDesktop: false,
            _meta: {
              htmlID: "u_row_1",
              htmlClassNames: "u_row",
            },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
      ],
      headers: [],
      footers: [],
      values: {
        _styleGuide: null,
        popupPosition: "center",
        popupWidth: "600px",
        popupHeight: "auto",
        borderRadius: "10px",
        contentAlign: "center",
        contentVerticalAlign: "center",
        contentWidth: "500px",
        fontFamily: {
          label: "Arial",
          value: "arial,helvetica,sans-serif",
        },
        textColor: "#000000",
        popupBackgroundColor: "#FFFFFF",
        popupBackgroundImage: {
          url: "",
          fullWidth: true,
          repeat: "no-repeat",
          size: "cover",
          position: "center",
        },
        popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
        popupCloseButton_position: "top-right",
        popupCloseButton_backgroundColor: "#DDDDDD",
        popupCloseButton_iconColor: "#000000",
        popupCloseButton_borderRadius: "0px",
        popupCloseButton_margin: "0px",
        popupCloseButton_action: {
          name: "close_popup",
          attrs: {
            onClick:
              "document.querySelector('.u-popup-container').style.display = 'none';",
          },
        },
        language: {},
        backgroundColor: "#F7F8F9",
        preheaderText: "",
        linkStyle: {
          body: true,
          linkColor: "#0000ee",
          linkHoverColor: "#0000ee",
          linkUnderline: true,
          linkHoverUnderline: true,
        },
        backgroundImage: {
          url: "",
          fullWidth: true,
          repeat: "no-repeat",
          size: "custom",
          position: "center",
        },
        _meta: {
          htmlID: "u_body",
          htmlClassNames: "u_body",
        },
      },
    },
    schemaVersion: 17,
  });
  const { token: accesstoken } = authData;
  useEffect(() => {
    const fetchData = async () => {
      if (accesstoken !== undefined) {
        let resp = await verifyToken(accesstoken);
        if (!resp.success) {
          toast.error("Session expired, please login again.");
          window.location.href = "/login";
        } else {
          setAuthUser(resp.user);
        }
      }
    };

    fetchData();
  }, [accesstoken]);

  useEffect(() => {
    const loadTemplate = async (desgin) => {
      if (desgin) {
        editorRef.current?.editor.loadDesign(desgin, (error) => {
          if (error) {
            console.log("Error loading template:", error);
          } else {
            console.log("Template loaded successfully.");
          }
        });
      } else {
        editorRef.current?.editor.loadDesign(baseTemplate.current, (error) => {
          if (error) {
            console.log("Error loading template:", error);
          } else {
            console.log("Template loaded successfully.");
          }
        });
      }
    };
    getTemplate(authData.user.id).then((res) => {
      loadTemplate(res?.template?.jsonDesign);
      setTime(res?.template?.time);
      setEnabled(res?.template?.enabled);
    });
  }, [authData]);

  const saveTemplate = () => {
    if (editorRef.current) {
      editorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        const userId = authData.user.id;
        if (userId) {
          if (time === "0") {
            toast.error("Time is required");
            return;
          }
          modifieTemplate({
            userId,
            html,
            jsonDesign: design,
            type: "email",
            time,
            enabled,
          })
            .then(() => {
              toast.success("Template saved successfully");
            })
            .catch((error) => {
              console.error("Error saving the template:", error);
              toast.error("Failed to save template");
            });
        }
      });
    } else {
      console.error("Editor instance not initialized.");
    }
  };

  const onReady = (unlayer) => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
    // console.log("unlayer ", unlayer);
    // unlayer.registerCallback("image", async (file, done) => {
    //   console.log("file ", file);
    //   axiosInstance.post();
    //   done({
    //     progress: 100,
    //     url: "https://studiio.studiio.au/static/media/studiio-au-Green.3c721e7c53b5c44da877.png",
    //   });
    // });
  };

  return (
    <div
      className="app-content content"
      style={{ padding: "20px 40px 20px 40px" }}
    >
      <div className="content-header-left col-md-4 col-12 mb-2">
        <h2>Email Template Editor</h2>
        <div className="row breadcrumbs-top">
          <div className="breadcrumb-wrapper col-12">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">Email Template</li>
            </ol>
          </div>
        </div>
      </div>
      <div className="bg-white p-2">
        <div className="d-flex justify-content-between">
          <div
            className="d-flex align-items-start"
            style={{
              gap: "4px",
            }}
          >
            <div
              style={{
                marginTop: "9px",
              }}
            >
              Enabled:
            </div>
            <Switch
              name="enable"
              checked={enabled}
              onChange={() => {
                setEnabled(!enabled);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
          <div className="d-flex justify-content-end mb-2 gap-4 align-items-end">
            <div>
              <label className="required">Select reminder time</label>
              <select
                className="select2 form-control"
                name="fromTime"
                id="fromTime"
                style={{ cursor: "pointer", maxWidth: "300px" }}
                required
                value={time}
                onChange={(e) => setTime(e.target.value)}
              >
                <option value="0">Select reminder time</option>
                <option value="1">1 hour before</option>
                <option value="2">2 hours before</option>
                <option value="3">3 hours before</option>
                <option value="4">4 hours before</option>
                <option value="5">5 hours before</option>
                <option value="6">6 hours before</option>
                <option value="7">7 hours before</option>
                <option value="8">8 hours before</option>
                <option value="9">9 hours before</option>
                <option value="10">10 hours before</option>
                <option value="11">11 hours before</option>
                <option value="12">12 hours before</option>
                <option value="13">13 hours before</option>
                <option value="14">14 hours before</option>
                <option value="15">15 hours before</option>
                <option value="16">16 hours before</option>
                <option value="17">17 hours before</option>
                <option value="18">18 hours before</option>
                <option value="19">19 hours before</option>
                <option value="20">20 hours before</option>
                <option value="21">21 hours before</option>
                <option value="22">22 hours before</option>
                <option value="23">23 hours before</option>
                <option value="24">24 hours before</option>
              </select>
            </div>
            <button
              className="btn btn-outline-primary py-2 ml-2"
              style={{ height: "40px" }}
              onClick={saveTemplate}
            >
              Save Template
            </button>
          </div>
        </div>
        <div
          style={{
            marginBottom: "20px",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          <EmailEditor
            ref={editorRef}
            style={{ height: "70vh" }}
            onReady={onReady}
          />
        </div>
      </div>
    </div>
  );
}

export default EmailTemplate;
